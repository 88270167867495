<template>
  <div class="page_container">
    <BackComponent :title="$t('withdrawFunds')" />
    <div>
      <img
        :src="
          this.currentLanguage === 'fr'
            ? '/img/banners/gifts/10-cashback-fr.webp'
            : '/img/banners/gifts/10-cashback-en.webp'
        "
        style="width: 100%; max-width: 100%; border-radius: 10px"
      />
    </div>
    <section class="account-details">
      <h5>{{ $t("selectPaymentProvider") }}</h5>

      <div v-if="loading">
        <MethodsLoader :count="4" />
      </div>
      <div
        v-else
        v-for="(provider, index) in paymentProviders"
        :key="index"
        class="wallet-info"
      >
        <router-link :to="provider.active && provider.link">
          <div class="account">
            <div>
              <img :src="provider.imgSrc" />
              <span>{{ provider.name }}</span>
            </div>
            <span v-if="provider.active">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M7.42499 16.6004L12.8583 11.1671C13.5 10.5254 13.5 9.47539 12.8583 8.83372L7.42499 3.40039"
                  stroke="#8C8787"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span v-else class="inactive">{{ $t("inactive") }} </span>
          </div>
        </router-link>
      </div>

      <div class="wallet-info" v-show="isDev && !loading" @click="handleAgent">
        <div class="account chop">
          <div>
            <img src="/img/payment/chopbet-agent.png" />
            <span>Chop agent</span>
          </div>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M7.42499 16.6004L12.8583 11.1671C13.5 10.5254 13.5 9.47539 12.8583 8.83372L7.42499 3.40039"
                stroke="#8C8787"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      </div>
    </section>
    <span id="open-shareinvite" class="hidden"></span>
    <CreatePin :isSlideUpOpen="isSlideUpOpen" type="withdraw" />
  </div>
</template>

<script>
import BackComponent from "../../components/ui/BackComponent.vue";
import CreatePin from "../../components/ui/CreatePin.vue";
import walletserve from "../../services/walletserve";
import MethodsLoader from "./components/MethodsLoader.vue";

export default {
  name: "Withdraw",
  components: {
    CreatePin,
    BackComponent: BackComponent,
    MethodsLoader,
  },
  mounted() {
    this.myProfile = this.getProfile();
    this.fetchPaymentMethods();
  },
  data() {
    return {
      isDev: process.env.VUE_APP_ENVIRONMENT === "development",
      myProfile: {},
      disabled: true,
      isSlideUpOpen: false,
      loading: false,
      paymentProviders: [
        {
          name: "Wave",
          imgSrc: "/img/payment/wave.webp",
          link: "/withdraw/wave",
          key: "wave",
        },
        {
          name: "Orange Money",
          imgSrc: "/img/payment/orange.webp",
          link: "/withdraw/orange",
          key: "orange",
        },
        {
          name: "MTN",
          imgSrc: "/img/payment/mtn.webp",
          link: "/withdraw/mtn",
          key: "mtn",
        },
        {
          name: "Moov Money",
          imgSrc: "/img/payment/moov.webp",
          link: "/withdraw/moov",
          key: "moov",
        },
      ],
    };
  },
  methods: {
    async fetchPaymentMethods() {
      this.loading = true;
      try {
        var path = "/methods";
        const res = await walletserve.get(path, {
          headers: {
            "api-key": this.getAuth(),
          },
        });
        this.paymentProviders.map((provider) => {
          const method = res.data.data.find(
            (method) => method.method === provider.key
          );
          if (method) {
            // if (method.method === "wave") {
            //   provider.active = false;
            // } else {
            //   provider.active = method.payout === 1;
            // }
            provider.active = method.payout === 1;
          }
          return provider;
        });

        this.loading = false;
      } catch (err) {
        this.loading = false;
        if (err.response) {
          const errorCode = parseInt(err.response.data.status);
          if (errorCode === 401 || errorCode === 428) {
            this.setError(err.response.data.message);
            this.logout();
            return;
          } else {
            this.setError(err.response.data.error_message);
          }
        } else if (err.request) {
          this.setError(
            `${this.$t("networkError")}`,
            `${this.$t("checkYourNetwork")}`
          );
        } else {
          // console.log(JSON.stringify(err));
        }
      }
    },
    handleAgent() {
      if (this.myProfile.has_set_pin) {
        this.$router.push("/withdraw/chop-agent");
        return;
      }
      this.isSlideUpOpen = !this.isSlideUpOpen;
      return;
    },
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style scoped>
.account-details {
  background: var(--background-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 160px);
  margin-top: 4px;
}

h5 {
  color: var(--text-color);
  font-family: "Satoshi";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.5px;
  margin-bottom: 12px;
}

.wallet-info {
  border-radius: 8px;
  background: var(--box-bg);
  margin-bottom: 8px;
}

.wallet-info > a {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.wallet-info > div {
  display: flex;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.account div {
  display: flex;
  align-items: center;
}

.account img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.account span {
  color: var(--text-color);
  font-family: "Satoshi";
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.1px;
}

.account {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.chop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.chop img {
  width: 24px;
  height: 17px;
  margin-right: 8px;
}

.inactive {
  display: flex;
  padding: var(--spacing-xs, 4px) var(--spacing-md, 8px);
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 70px;
  background: var(--Light-Mode-Red-Red-200, #f7d9d9);
  color: #cc1717 !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.1px;
}
</style>
